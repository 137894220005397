export default {
  namespaced: true,
  state: {
    text: '',
    status: false,
    color: 'success',
  },
  mutations: {
    SET_CONTENT(state, { status, text, color }) {
      state.status = !!status
      state.text = text || ''
      state.color = color
    },
  },
  actions: {
    set({ commit }, payload) {
      commit('SET_CONTENT', payload)
    },
  },
}
